import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Button, Icon } from "@mui/material";
import SocialsBar from "./SocialsBar";
import ArtistDetailsSlide from "./CarouselItems/ArtistDetailsSlide";
import CommunitySlide from "./CarouselItems/CommunitySlide";
import SpotlightReleaseSlide from "./CarouselItems/SpotlightReleaseSlide";
import VideoSlide from "./CarouselItems/VideoSlide";
import { WebsiteBuilderModel } from "../models/ArtistDetails";

export default function Carousel({
  artistDetails,
  theme,
}: {
  artistDetails: WebsiteBuilderModel;
  theme: string;
}) {
  const [slideIndex, setSlideIndex] = useState<number>(0);

  const [breakPointHit, setBreakPointHit] = useState<boolean>(false);

  const handleResize = () => {
    window.visualViewport && window.visualViewport.width <= 900
      ? setBreakPointHit(true)
      : setBreakPointHit(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [window.innerWidth]);

  const slider: any = useRef();

  return (
    <div>
      <Swiper
        ref={slider}
        spaceBetween={breakPointHit ? 16 : 100}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView={1.3}
        navigation={true}
        centeredSlides={true}
        pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <ArtistDetailsSlide artistDetails={artistDetails} />
        </SwiperSlide>

        <SwiperSlide>
          <CommunitySlide artistDetails={artistDetails} />
        </SwiperSlide>

        {artistDetails?.spotlightRelease && (
          <SwiperSlide>
            <SpotlightReleaseSlide artistDetails={artistDetails} />
          </SwiperSlide>
        )}
        {(artistDetails?.video?.url || artistDetails?.video?.embed) && (
          <SwiperSlide>
            <VideoSlide artistDetails={artistDetails} />
          </SwiperSlide>
        )}

        {breakPointHit && artistDetails?.platformLinks && (
          <SwiperSlide>
            <div className="carousel-card">
              <h3 className="mb48 mt20">
                Find {artistDetails?.artistName} on other platforms
              </h3>

              <SocialsBar links={artistDetails?.platformLinks} theme={theme} />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
      <div className="swiper-button-next">
        <Button
          className="icon-button"
          disabled={slideIndex === slider?.current?.swiper?.slides?.length - 1}
          onClick={() => {
            slider.current.swiper.slideNext();
            setSlideIndex(slider.current.swiper.activeIndex);
          }}
        >
          <Icon>chevron_right</Icon>
        </Button>
      </div>
      <div className="swiper-button-prev">
        <Button
          className="icon-button"
          disabled={slideIndex === 0}
          onClick={() => {
            slider.current.swiper.slidePrev();
            setSlideIndex(slider.current.swiper.activeIndex);
          }}
        >
          <Icon>chevron_left</Icon>
        </Button>
      </div>
    </div>
  );
}
